import React, { useEffect } from 'react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import PageLayout from '../../layouts/Page';
import Hero from "../../components/Hero";

import './contact.scss';

const SchedulePage = props => {
  const { location } = props;
  const mixpanel = useMixpanel();

  useEffect(() => {
    mixpanel.track('Sales Thank you page Viewed');
  }, [mixpanel]);

  return (
    <div className="SchedulePage">
      <PageLayout 
        location={location} title="Thank you" 
        description=""
      >
        <Hero>
          <Hero.Title>Thanks for reaching out!</Hero.Title>
          <Hero.Text>We've received your submission and one of our experts will be in touch shortly. In the meantime, check out our <OutboundLink href="/docs/getting-started/">docs</OutboundLink> and <Link to="/blog/">blog</Link> for learning resources.</Hero.Text>
        </Hero>
      </PageLayout>
    </div>
  );
};

export default SchedulePage;
